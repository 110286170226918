import React, { useState, useEffect, useCallback } from "react";
import { collection, getDocs, addDoc, doc, setDoc } from "firebase/firestore";
import { Plus, Upload, FolderPlus } from "lucide-react";
import HomeButton from "./home";

const Publish = ({ db }) => {
  const [products, setProducts] = useState([{ Title: "", modelNumber: "", img: "" }]);
  const [collections, setCollections] = useState([]);
  const [selectedCollection, setSelectedCollection] = useState("");
  const [newCollection, setNewCollection] = useState("");

  const fetchCollections = useCallback(async () => {
    try {
      const collectionsRef = collection(db, "products", "lights", "collections");
      const collectionsSnapshot = await getDocs(collectionsRef);
      const fetchedCollections = collectionsSnapshot.docs.map(doc => doc.id);
      setCollections(fetchedCollections);
    } catch (error) {
      console.error("Error fetching collections:", error);
    }
  }, [db]);

  useEffect(() => {
    fetchCollections();
  }, [fetchCollections]);

  const handleInputChange = (index, e) => {
    const { name, value } = e.target;
    const updatedProducts = [...products];
    updatedProducts[index] = { ...updatedProducts[index], [name]: value };
    setProducts(updatedProducts);
  };

  const handleAddProduct = () => {
    setProducts([...products, { Title: "", modelNumber: "", img: "" }]);
  };

  const handleRemoveProduct = (index) => {
    if (products.length > 1) {
      const updatedProducts = products.filter((_, i) => i !== index);
      setProducts(updatedProducts);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedCollection) {
      alert("Please select a collection");
      return;
    }
    try {
      const productsRef = collection(db, "products", "lights", "collections", selectedCollection, "products");
      for (const product of products) {
        await addDoc(productsRef, product);
      }
      setProducts([{ Title: "", modelNumber: "", img: "" }]);
      alert("Products added successfully!");
    } catch (error) {
      console.error("Error adding products:", error);
      alert("Error adding products. Please try again.");
    }
  };

  const handleAddCollection = async (e) => {
    e.preventDefault();
    if (!newCollection) return;
    try {
      const newCollectionRef = doc(db, "products", "lights", "collections", newCollection);
      await setDoc(newCollectionRef, {});
      setNewCollection("");
      fetchCollections();
      alert("Collection added successfully!");
    } catch (error) {
      console.error("Error adding collection:", error);
      alert("Error adding collection. Please try again.");
    }
  };

  return (
    <div style={{
      fontFamily: 'Arial, sans-serif',
      maxWidth: '1200px',
      margin: '0 auto',
      padding: '20px',
    }}>
      <HomeButton />
      <h1 style={{
        fontSize: '24px',
        fontWeight: 'bold',
        marginBottom: '20px',
      }}>Product Dashboard</h1>
      
      <div style={{
        backgroundColor: '#f8f9fa',
        borderRadius: '4px',
        padding: '20px',
        marginBottom: '20px',
      }}>
        <h2 style={{
          fontSize: '20px',
          fontWeight: 'bold',
          marginBottom: '15px',
        }}>Add Products</h2>
        <form onSubmit={handleSubmit}>
          {products.map((product, index) => (
            <div key={index} style={{
              display: 'flex',
              marginBottom: '10px',
            }}>
              <input
                type="text"
                name="Title"
                value={product.Title}
                onChange={(e) => handleInputChange(index, e)}
                placeholder="Title"
                style={{
                  flex: 1,
                  padding: '8px',
                  marginRight: '10px',
                  border: '1px solid #ced4da',
                  borderRadius: '4px',
                }}
                required
              />
              <input
                type="text"
                name="modelNumber"
                value={product.modelNumber}
                onChange={(e) => handleInputChange(index, e)}
                placeholder="Model Number"
                style={{
                  flex: 1,
                  padding: '8px',
                  marginRight: '10px',
                  border: '1px solid #ced4da',
                  borderRadius: '4px',
                }}
                required
              />
              <input
                type="text"
                name="img"
                value={product.img}
                onChange={(e) => handleInputChange(index, e)}
                placeholder="Image URL"
                style={{
                  flex: 1,
                  padding: '8px',
                  marginRight: '10px',
                  border: '1px solid #ced4da',
                  borderRadius: '4px',
                }}
                required
              />
              {index > 0 && (
                <button
                  type="button"
                  onClick={() => handleRemoveProduct(index)}
                  style={{
                    padding: '8px 12px',
                    backgroundColor: '#dc3545',
                    color: 'white',
                    border: 'none',
                    borderRadius: '4px',
                    cursor: 'pointer',
                  }}
                >
                  Remove
                </button>
              )}
            </div>
          ))}
          <div style={{
            display: 'flex',
            marginTop: '10px',
          }}>
            <button
              type="button"
              onClick={handleAddProduct}
              style={{
                padding: '8px 12px',
                backgroundColor: '#28a745',
                color: 'white',
                border: 'none',
                borderRadius: '4px',
                marginRight: '10px',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Plus size={18} style={{ marginRight: '5px' }} /> Add Product
            </button>
            <select
              value={selectedCollection}
              onChange={(e) => setSelectedCollection(e.target.value)}
              style={{
                flex: 1,
                padding: '8px',
                border: '1px solid #ced4da',
                borderRadius: '4px',
                marginRight: '10px',
              }}
              required
            >
              <option value="">Select a collection</option>
              {collections.map((collection) => (
                <option key={collection} value={collection}>{collection}</option>
              ))}
            </select>
            <button type="submit" style={{
              padding: '8px 12px',
              backgroundColor: '#007bff',
              color: 'white',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
            }}>
              <Upload size={18} style={{ marginRight: '5px' }} /> Publish All
            </button>
          </div>
        </form>
      </div>

      <div style={{
        backgroundColor: '#f8f9fa',
        borderRadius: '4px',
        padding: '20px',
      }}>
        <h2 style={{
          fontSize: '20px',
          fontWeight: 'bold',
          marginBottom: '15px',
        }}>Add New Collection</h2>
        <form onSubmit={handleAddCollection} style={{
          display: 'flex',
        }}>
          <input
            type="text"
            value={newCollection}
            onChange={(e) => setNewCollection(e.target.value)}
            style={{
              flex: 1,
              padding: '8px',
              marginRight: '10px',
              border: '1px solid #ced4da',
              borderRadius: '4px',
            }}
            placeholder="New Collection Name"
            required
          />
          <button type="submit" style={{
            padding: '8px 12px',
            backgroundColor: '#17a2b8',
            color: 'white',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
          }}>
            <FolderPlus size={18} style={{ marginRight: '5px' }} /> Add Collection
          </button>
        </form>
      </div>
    </div>
  );
};

export default Publish;