import React, { useState, useEffect } from 'react';
import { collection, getDocs, query, orderBy, updateDoc, doc } from 'firebase/firestore';
import { db } from './config'; 
import HomeButton from './home';

function Queries() {
  const [queries, setQueries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState('all');
  const [selectedQuery, setSelectedQuery] = useState(null);

  useEffect(() => {
    const fetchQueries = async () => {
      try {
        const q = query(collection(db, 'queries'), orderBy('timestamp', 'desc'));
        const querySnapshot = await getDocs(q);
        const queriesData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          timestamp: doc.data().timestamp.toDate().toLocaleString(),
          read: doc.data().read || false
        }));
        setQueries(queriesData);
      } catch (error) {
        console.error("Error fetching queries: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchQueries();
  }, []);

  const handleToggleRead = async (id, currentReadStatus) => {
    try {
      await updateDoc(doc(db, 'queries', id), { read: !currentReadStatus });
      setQueries(queries.map(q => q.id === id ? {...q, read: !currentReadStatus} : q));
    } catch (error) {
      console.error("Error updating query: ", error);
    }
  };

  const filteredQueries = queries.filter(query => {
    if (filter === 'all') return true;
    if (filter === 'read') return query.read;
    if (filter === 'unread') return !query.read;
    return true;
  });

  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  return (
    <div className="queries-container">
      <HomeButton/>
      <style jsx>{`
        .queries-container {
          max-width: 100%;
          margin: 0 auto;
          padding: 20px;
          font-family: Arial, sans-serif;
          overflow-x: auto;
        }
        h2 {
          color: #333;
          text-align: center;
          margin-bottom: 20px;
        }
        .filter-container {
          margin-bottom: 20px;
          text-align: right;
        }
        .filter-container select {
          padding: 5px 10px;
          font-size: 16px;
        }
        .queries-table {
          width: 100%;
          border-collapse: collapse;
          background-color: #fff;
          box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
        }
        .queries-table th,
        .queries-table td {
          padding: 12px 15px;
          text-align: left;
          border-bottom: 1px solid #e0e0e0;
          max-width: 200px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .queries-table th {
          background-color: #f5f5f5;
          font-weight: bold;
          text-transform: uppercase;
          font-size: 0.9em;
          color: #333;
        }
        .queries-table tr:hover {
          background-color: #f5f5f5;
        }
        .loading {
          text-align: center;
          font-size: 1.2em;
          color: #666;
          margin-top: 50px;
        }
        .toggle-read-btn {
          background-color: #4CAF50;
          border: none;
          color: white;
          padding: 5px 10px;
          text-align: center;
          text-decoration: none;
          display: inline-block;
          font-size: 14px;
          margin: 2px 2px;
          cursor: pointer;
          border-radius: 3px;
        }
        .read {
          opacity: 0.6;
        }
        .modal {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.6);
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .modal-content {
          background: white;
          padding: 20px;
          border-radius: 5px;
          max-width: 80%;
          max-height: 80%;
          overflow-y: auto;
        }
        .close-btn {
          float: right;
          cursor: pointer;
        }
        @media (max-width: 768px) {
          .queries-table {
            font-size: 0.8em;
          }
          .queries-table th,
          .queries-table td {
            padding: 8px 10px;
          }
        }
      `}</style>

      <h2>Customer Queries</h2>

      <div className="filter-container">
        <select value={filter} onChange={(e) => setFilter(e.target.value)}>
          <option value="all">All Queries</option>
          <option value="read">Read Queries</option>
          <option value="unread">Unread Queries</option>
        </select>
      </div>

      {filteredQueries.length === 0 ? (
        <p>No queries found.</p>
      ) : (
        <table className="queries-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Message</th>
              <th>Timestamp</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {filteredQueries.map((query) => (
              <tr key={query.id} className={query.read ? 'read' : ''}>
                <td>{query.name}</td>
                <td>{query.email}</td>
                <td>{query.phone}</td>
                <td onClick={() => setSelectedQuery(query)} style={{cursor: 'pointer'}}>{query.message}</td>
                <td>{query.timestamp}</td>
                <td>{query.read ? 'Read' : 'Unread'}</td>
                <td>
                  <button 
                    className="toggle-read-btn"
                    onClick={() => handleToggleRead(query.id, query.read)}
                  >
                    {query.read ? 'Mark as Unread' : 'Mark as Read'}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {selectedQuery && (
        <div className="modal">
          <div className="modal-content">
            <span className="close-btn" onClick={() => setSelectedQuery(null)}>&times;</span>
            <h3>Full Message</h3>
            <p><strong>From:</strong> {selectedQuery.name}</p>
            <p><strong>Email:</strong> {selectedQuery.email}</p>
            <p><strong>Phone:</strong> {selectedQuery.phone}</p>
            <p><strong>Timestamp:</strong> {selectedQuery.timestamp}</p>
            <p><strong>Message:</strong></p>
            <p>{selectedQuery.message}</p>
          </div>
        </div>
      )}
    </div>
  );
}

export default Queries;