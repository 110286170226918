import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Home } from 'lucide-react';

const HomeButton = () => {
  const navigate = useNavigate();

  const styles = {
    button: {
      position: 'absolute',
      top: '20px',
      left: '20px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '10px 15px',
      backgroundColor: '#4a90e2',
      color: '#ffffff',
      border: 'none',
      borderRadius: '8px',
      cursor: 'pointer',
      fontFamily: 'Arial, sans-serif',
      fontSize: '16px',
      fontWeight: 'bold',
      textDecoration: 'none',
      transition: 'background-color 0.3s ease',
    },
    icon: {
      marginRight: '8px',
    },
  };

  return (
    <button
      onClick={() => navigate('/Admin')}
      style={styles.button}
      onMouseEnter={(e) => e.target.style.backgroundColor = '#3a7bc8'}
      onMouseLeave={(e) => e.target.style.backgroundColor = '#4a90e2'}
    >
      <Home style={styles.icon} size={18} />
      Home
    </button>
  );
};

export default HomeButton;