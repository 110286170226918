import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Package, PlusCircle, MessageCircle } from 'lucide-react';

const AdminDashboard = () => {
  const navigate = useNavigate();

  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '100vh',
      backgroundColor: '#f0f2f5',
      fontFamily: 'Arial, sans-serif',
    },
    header: {
      fontSize: '2.5rem',
      fontWeight: 'bold',
      color: '#333',
      marginBottom: '2rem',
    },
    buttonContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '1.5rem',
      width: '100%',
      maxWidth: '400px',
    },
    button: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '1.5rem',
      fontSize: '1.25rem',
      fontWeight: 'bold',
      color: '#fff',
      backgroundColor: '#4a90e2',
      border: 'none',
      borderRadius: '12px',
      cursor: 'pointer',
      transition: 'transform 0.1s ease-in-out, box-shadow 0.1s ease-in-out',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    },
    buttonHover: {
      transform: 'translateY(-2px)',
      boxShadow: '0 6px 8px rgba(0, 0, 0, 0.15)',
    },
    icon: {
      marginRight: '0.75rem',
    },
  };

  const handleButtonClick = (route) => {
    navigate(route);
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.header}><span
      style={{color:'#E2B127'}}
      >Divubrite</span> <br></br>Admin Dashboard</h1>
      <div style={styles.buttonContainer}>
        <button
          style={styles.button}
          onMouseEnter={(e) => Object.assign(e.target.style, styles.buttonHover)}
          onMouseLeave={(e) => Object.assign(e.target.style, styles.button)}
          onClick={() => handleButtonClick('/products')}
        >
          <Package style={styles.icon} size={24} />
          Products
        </button>
        <button
          style={styles.button}
          onMouseEnter={(e) => Object.assign(e.target.style, styles.buttonHover)}
          onMouseLeave={(e) => Object.assign(e.target.style, styles.button)}
          onClick={() => handleButtonClick('/publish')}
        >
          <PlusCircle style={styles.icon} size={24} />
          Create New Product
        </button>
        <button
          style={styles.button}
          onMouseEnter={(e) => Object.assign(e.target.style, styles.buttonHover)}
          onMouseLeave={(e) => Object.assign(e.target.style, styles.button)}
          onClick={() => handleButtonClick('/queries')}
        >
          <MessageCircle style={styles.icon} size={24} />
          Queries
        </button>
      </div>
    </div>
  );
};

export default AdminDashboard;