import React, { useState, useEffect, useCallback } from "react";
import { collection, getDocs, deleteDoc, doc, updateDoc } from "firebase/firestore";
import { Trash2, Filter, Copy, List, Edit, Save } from "lucide-react";
import HomeButton from "./home";

const Products = ({ db }) => {
  const [products, setProducts] = useState([]);
  const [collections, setCollections] = useState(["All"]);
  const [selectedCollection, setSelectedCollection] = useState("All");
  const [searchTerm, setSearchTerm] = useState("");
  const [duplicates, setDuplicates] = useState([]);
  const [showDuplicates, setShowDuplicates] = useState(false);
  const [editMode, setEditMode] = useState({});

  const fetchCollections = useCallback(async () => {
    try {
      const collectionsRef = collection(db, "products", "lights", "collections");
      const collectionsSnapshot = await getDocs(collectionsRef);
      const fetchedCollections = collectionsSnapshot.docs.map(doc => doc.id);
      setCollections(["All", ...fetchedCollections]);
    } catch (error) {
      console.error("Error fetching collections:", error);
    }
  }, [db]);

  const fetchProducts = useCallback(async () => {
    try {
      const allProducts = [];
      const collectionsRef = collection(db, "products", "lights", "collections");
      const collectionsSnapshot = await getDocs(collectionsRef);
      
      for (const collectionDoc of collectionsSnapshot.docs) {
        const collectionName = collectionDoc.id;
        const productsRef = collection(db, "products", "lights", "collections", collectionName, "products");
        const productsSnapshot = await getDocs(productsRef);
        const collectionProducts = productsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          collection: collectionName
        }));
        allProducts.push(...collectionProducts);
      }
      setProducts(allProducts);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  }, [db]);

  useEffect(() => {
    fetchCollections();
    fetchProducts();
  }, [fetchCollections, fetchProducts]);

  const handleDelete = async (productId, collectionName) => {
    if (window.confirm("Are you sure you want to delete this product?")) {
      try {
        const productRef = doc(db, "products", "lights", "collections", collectionName, "products", productId);
        await deleteDoc(productRef);
        setProducts(products.filter(product => product.id !== productId));
        alert("Product deleted successfully!");
      } catch (error) {
        console.error("Error deleting product:", error);
        alert("Error deleting product. Please try again.");
      }
    }
  };

  const handleEdit = (productId) => {
    setEditMode(prev => ({ ...prev, [productId]: true }));
  };

  const handleSave = async (product) => {
    try {
      const productRef = doc(db, "products", "lights", "collections", product.collection, "products", product.id);
      await updateDoc(productRef, {
        Title: product.Title,
        img: product.img,
        modelNumber: product.modelNumber
      });
      setEditMode(prev => ({ ...prev, [product.id]: false }));
      setProducts(products.map(p => p.id === product.id ? product : p));
      alert("Product updated successfully!");
    } catch (error) {
      console.error("Error updating product:", error);
      alert("Error updating product. Please try again.");
    }
  };

  const findDuplicates = useCallback(() => {
    const productMap = new Map();
    const duplicatesMap = new Map();

    products.forEach((product) => {
      const key = `${product.Title}-${product.modelNumber}`;
      if (productMap.has(key)) {
        if (duplicatesMap.has(key)) {
          duplicatesMap.get(key).push(product);
        } else {
          duplicatesMap.set(key, [productMap.get(key), product]);
        }
      } else {
        productMap.set(key, product);
      }
    });

    const duplicatesList = Array.from(duplicatesMap.values()).flat();
    setDuplicates(duplicatesList);
    setShowDuplicates(true);
  }, [products]);

  const removeDuplicates = useCallback(() => {
    if (window.confirm("Are you sure you want to remove all duplicate products?")) {
      const uniqueProducts = [];
      const seen = new Set();

      products.forEach((product) => {
        const key = `${product.Title}-${product.modelNumber}`;
        if (!seen.has(key)) {
          seen.add(key);
          uniqueProducts.push(product);
        }
      });

      // Delete duplicate products from Firestore
      const deletions = products
        .filter((product) => !uniqueProducts.includes(product))
        .map((product) => 
          deleteDoc(doc(db, "products", "lights", "collections", product.collection, "products", product.id))
        );

      Promise.all(deletions)
        .then(() => {
          setProducts(uniqueProducts);
          setDuplicates([]);
          setShowDuplicates(false);
          alert("Duplicate products have been removed successfully!");
        })
        .catch((error) => {
          console.error("Error removing duplicates:", error);
          alert("Error removing duplicates. Please try again.");
        });
    }
  }, [products, db]);

  const filteredProducts = showDuplicates ? duplicates : products.filter(product => 
    (selectedCollection === "All" || product.collection === selectedCollection) &&
    (product.Title.toLowerCase().includes(searchTerm.toLowerCase()) ||
     product.modelNumber.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  const styles = {
    container: {
      fontFamily: 'Arial, sans-serif',
      maxWidth: '1200px',
      margin: '0 auto',
      padding: '20px',
    },
    header: {
      fontSize: '28px',
      fontWeight: 'bold',
      marginBottom: '20px',
      color: '#333',
    },
    filterSection: {
      backgroundColor: '#f0f0f0',
      borderRadius: '8px',
      padding: '20px',
      marginBottom: '20px',
    },
    filterTitle: {
      fontSize: '20px',
      fontWeight: 'bold',
      marginBottom: '10px',
      display: 'flex',
      alignItems: 'center',
    },
    select: {
      padding: '10px',
      marginRight: '10px',
      borderRadius: '4px',
      border: '1px solid #ccc',
    },
    input: {
      padding: '10px',
      borderRadius: '4px',
      border: '1px solid #ccc',
      width: 'calc(100% - 20px)',
    },
    productsGrid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
      gap: '20px',
    },
    productCard: {
      backgroundColor: 'white',
      borderRadius: '8px',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
      overflow: 'hidden',
    },
    productImage: {
      width: '100%',
      height: '200px',
      objectFit: 'cover',
    },
    productInfo: {
      padding: '15px',
    },
    productTitle: {
      fontSize: '18px',
      fontWeight: 'bold',
      marginBottom: '5px',
    },
    productModel: {
      fontSize: '14px',
      color: '#666',
      marginBottom: '5px',
    },
    productCollection: {
      fontSize: '14px',
      color: '#666',
      marginBottom: '10px',
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '20px',
    },
    actionButton: {
      backgroundColor: '#4CAF50',
      color: 'white',
      border: 'none',
      padding: '10px 15px',
      borderRadius: '4px',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    deleteButton: {
      backgroundColor: '#ff4d4d',
      color: 'white',
      border: 'none',
      padding: '8px 12px',
      borderRadius: '4px',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      marginBottom: '10px',
    },
    editButton: {
      backgroundColor: '#4CAF50',
      color: 'white',
      border: 'none',
      padding: '8px 12px',
      borderRadius: '4px',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
    },
    editInput: {
      width: '100%',
      padding: '8px',
      marginBottom: '10px',
      borderRadius: '4px',
      border: '1px solid #ccc',
    },
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.header}>Products Dashboard</h1>
      <HomeButton />
      <div style={styles.filterSection}>
        <div style={styles.filterTitle}>
          <Filter style={{ marginRight: '8px' }} />
          <span>Filters</span>
        </div>
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
          <select
            value={selectedCollection}
            onChange={(e) => setSelectedCollection(e.target.value)}
            style={styles.select}
          >
            {collections.map((collection) => (
              <option key={collection} value={collection}>{collection}</option>
            ))}
          </select>
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search by title or model number"
            style={styles.input}
          />
        </div>
      </div>

      <div style={styles.buttonContainer}>
        <button onClick={findDuplicates} style={styles.actionButton}>
          <List style={{ marginRight: '8px' }} />
          List Duplicates
        </button>
        <button onClick={removeDuplicates} style={styles.actionButton}>
          <Copy style={{ marginRight: '8px' }} />
          Remove Duplicates
        </button>
      </div>

      {showDuplicates && (
        <div style={{ marginBottom: '20px', color: '#666' }}>
          Showing {duplicates.length} duplicate products. 
          <button 
            onClick={() => setShowDuplicates(false)} 
            style={{ marginLeft: '10px', padding: '5px 10px', cursor: 'pointer' }}
          >
            Show All Products
          </button>
        </div>
      )}

      <div style={styles.productsGrid}>
        {filteredProducts.map((product) => (
          <div key={product.id} style={styles.productCard}>
            {editMode[product.id] ? (
              <div style={styles.productInfo}>
                <input
                  style={styles.editInput}
                  value={product.Title}
                  onChange={(e) => setProducts(products.map(p => p.id === product.id ? {...p, Title: e.target.value} : p))}
                  placeholder="Title"
                />
                <input
                  style={styles.editInput}
                  value={product.img}
                  onChange={(e) => setProducts(products.map(p => p.id === product.id ? {...p, img: e.target.value} : p))}
                  placeholder="Image URL"
                />
                <input
                  style={styles.editInput}
                  value={product.modelNumber}
                  onChange={(e) => setProducts(products.map(p => p.id === product.id ? {...p, modelNumber: e.target.value} : p))}
                  placeholder="Model Number"
                />
                <button
                  onClick={() => handleSave(product)}
                  style={styles.editButton}
                >
                  <Save style={{ marginRight: '5px' }} size={18} />
                  Save
                </button>
              </div>
            ) : (
              <>
                <img src={product.img} alt={product.Title} style={styles.productImage} />
                <div style={styles.productInfo}>
                  <h3 style={styles.productTitle}>{product.Title}</h3>
                  <p style={styles.productModel}>Model: {product.modelNumber}</p>
                  <p style={styles.productCollection}>Collection: {product.collection}</p>
                  <button
                    onClick={() => handleDelete(product.id, product.collection)}
                    style={styles.deleteButton}
                  >
                    <Trash2 style={{ marginRight: '5px' }} size={18} />
                    Delete
                  </button>
                  <button
                    onClick={() => handleEdit(product.id)}
                    style={styles.editButton}
                  >
                    <Edit style={{ marginRight: '5px' }} size={18} />
                    Edit
                  </button>
                </div>
              </>
            )}
          </div>
        ))}
      </div>

      {filteredProducts.length === 0 && (
        <div style={{ textAlign: 'center', color: '#666', marginTop: '20px' }}>
          No products found. Try adjusting your filters.
        </div>
      )}
    </div>
  );
};

export default Products;