import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { db } from './config';
import Login from './login';
import Publish from './publish';
import Products from './products';
import Queries from './queries';
import AdminDashboard from './AdminDashboard';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsLoggedIn(!!user);
      setIsInitialized(true);
    });

    return () => unsubscribe();
  }, []);

  if (!isInitialized) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/admin" element={
          <PrivateRoute isLoggedIn={isLoggedIn}>
            <AdminDashboard />
          </PrivateRoute>
        } />
        <Route path="/publish" element={
          <PrivateRoute isLoggedIn={isLoggedIn}>
            <Publish db={db} />
          </PrivateRoute>
        } />
        <Route path="/queries" element={
          <PrivateRoute isLoggedIn={isLoggedIn}>
            <Queries db={db} />            
          </PrivateRoute>
        } />
        <Route path="/products" element={
          <PrivateRoute isLoggedIn={isLoggedIn}>
            <Products db={db} />
          </PrivateRoute>
        } />
        <Route path="/" element={isLoggedIn ? <Navigate to="/admin" /> : <Navigate to="/login" />} />
      </Routes>
    </Router>
  );
}

const PrivateRoute = ({ isLoggedIn, children }) => {
  return isLoggedIn ? children : <Navigate to="/login" />;
};

export default App;